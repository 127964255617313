
import { defineComponent, reactive, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { ipRegexp } from '@/services/utils'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
  },
  setup() {
    const whitelist = reactive<any>({
      enabled: true,
      type: 'country',
      countries: [],
      ips: [],
    })

    const currentCountry = ref({ id: 'ee', name: 'Estonia' })
    const addCurrentCountry = () => {
      whitelist.countries.push(currentCountry.value)
    }

    const currentIp = ref('192.198.8.2')
    const addCurrentIp = () => {
      whitelist.ips.push({ text: currentIp, valid: true })
    }

    return {
      whitelist,
      ipRegexp,
      currentCountry,
      addCurrentCountry,
      currentIp,
      addCurrentIp,
    }
  },
})
